import React from "react";
import { motion } from "framer-motion";

export default function SlideFadeWrapper({
  children,
  delay=0.3,
}: {
  children: React.ReactNode;
  delay?: number
}) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0, y: 50 }}
    >
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay }}
        exit={{ opacity: 0, y: 50 }}
      >
        {children}
      </motion.div>
    </motion.div>
  );
}
