import React from "react";
import { Routes, Route } from "react-router-dom";

import "./index.css";

import { createTheme, ThemeProvider } from "@mui/material";

import Landing from "./Landing";
import Support from "./Support";
import Terms from "./Terms";
import Privacy from "./Privacy";

const theme = createTheme({});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="support" element={<Support />} />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<Privacy />} />
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
