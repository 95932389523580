import React from "react";
import { Box } from "@mui/material";

interface Props {
  title: string;
  description: string;
  points: string[];
  reverse?: boolean;
  image: JSX.Element;
}

export default function Section(props: Props) {
  const content = (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      ml={{ xs: 0, md: props.reverse ? 0 : 5 }}
      mr={{ xs: 0, md: props.reverse ? 5 : 0 }}
      mt={{ xs: 5, md: 0 }}
      textAlign={{ xs: "center", md: "left" }}
    >
      <p style={{ fontWeight: "bold", fontSize: 45 }}>{props.title}</p>
      <p style={{ fontSize: 28, marginTop: 10 }}>{props.description}</p>

      <Box mt={1} textAlign="left">
        {props.points.map((point, i) => (
          <Box display="flex" alignItems="center" mt={2} key={i}>
            <Box
              bgcolor="white"
              width={50}
              height={50}
              minWidth={50}
              minHeight={50}
              borderRadius={25}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img src="check.png" alt="checkmark" height={32} />
            </Box>
            <p style={{ fontSize: 20, marginLeft: 10 }}>{point}</p>
          </Box>
        ))}
      </Box>
    </Box>
  );

  return (
    <Box
      display="flex"
      flexDirection={{ xs: "column", md: "row" }}
      maxWidth={1200}
      margin="0 auto"
    >
      {props.reverse ? content : null}
      <Box
        bgcolor="#1f1f1f"
        height={700}
        width="100%"
        borderRadius={10}
        justifyContent="center"
        alignItems="center"
        display="flex"
      >
        {props.image}
      </Box>
      {!props.reverse ? content : null}
    </Box>
  );
}
