import React from "react";

function Support() {
  return (
    <div>
      <h2>
        For more information on support, email{" "}
        <a href="mailto:thetalonapp@gmail.com">thetalonapp@gmail.com</a>
      </h2>
    </div>
  );
}

export default Support;
