import React from "react";

import { Box, useMediaQuery, Theme, Stack, Grid } from "@mui/material";

import SlideUpFade from "./SlideUpFade";
import Section from "./Section";

export default function Landing() {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );

  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  return (
    <>
      <Box>
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent="center"
          alignItems="center"
          height={{ lg: "100vh" }}
          width="100vw"
        >
          <SlideUpFade>
            <Box display="flex" justifyContent="center">
              <img
                src="main.png"
                alt="workout player screenshot"
                width={isSmallScreen ? "70%" : 360}
              />
            </Box>
          </SlideUpFade>

          <Box minWidth={0} maxWidth={450}>
            <SlideUpFade delay={0.8}>
              <Box
                display="flex"
                flexDirection={{ xs: "column", md: "row" }}
                alignItems="center"
                marginBottom={3}
              >
                <img src="icon.svg" alt="talon logo" height={100} />
                <p
                  style={{
                    fontWeight: 700,
                    fontSize: 72,
                    letterSpacing: 20,
                    marginLeft: 20,
                  }}
                >
                  TALON
                </p>
              </Box>
            </SlideUpFade>

            <SlideUpFade delay={1.2}>
              <Box textAlign={{ xs: "center", md: "left" }}>
                <p style={{ fontWeight: 400, fontSize: 44 }}>
                  Simple, unobtrusive workout & nutrition tracking.
                </p>
              </Box>
            </SlideUpFade>

            <Box>
              <SlideUpFade delay={1.6}>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  alignItems="bottom"
                  marginTop={5}
                  justifyContent={{ xs: "center", md: "left" }}
                >
                  <Box
                    marginRight={{ xs: 0, sm: 2 }}
                    marginBottom={{ xs: 2, sm: 0 }}
                  >
                    <a href="https://apps.apple.com/us/app/talon-custom-workout-tracker/id6452948144">
                      <img
                        src="app_store_download.svg"
                        alt="download on app store"
                        height={67}
                        width={200}
                      />
                    </a>
                  </Box>

                  <Box marginRight={{ xs: 0, sm: 2 }}>
                    <a href="https://play.google.com/store/apps/details?id=com.rz0605.Talon">
                      <img
                        src="google-play-badge.png"
                        alt="download on google play"
                        height={64}
                        width={218}
                      />
                    </a>
                  </Box>
                </Stack>

                {/* INSERT PRODUCT HUNT EMBED HERE */}
                <Box marginTop={{ xs: 2, sm: 1 }}>
                  <a href="https://www.producthunt.com/posts/talon-fitness?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-talon&#0045;fitness">
                    <img
                      src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=411216&theme=dark"
                      alt="Talon&#0032;Fitness - Simple&#0044;&#0032;unobtrusive&#0032;workout&#0032;&#0038;&#0032;nutrition&#0032;tracking | Product Hunt"
                      style={{ width: 250, height: 54 }}
                      width="250"
                      height="54"
                    />
                  </a>
                </Box>
              </SlideUpFade>
            </Box>
          </Box>
        </Box>

        <Box width="70%" margin="0 auto" mt={{ xs: 10, md: 0 }}>
          <Box>
            <Section
              image={
                <img
                  src="exercise.png"
                  alt="create exercise screenshot"
                  width="60%"
                  style={{ marginTop: 25, minWidth: 280 }}
                />
              }
              title="All types of exercises."
              description="Sometimes your custom exercises don't conform to regular metrics. We've got you covered."
              points={[
                "Reps, weight, duration, and distance types",
                "One rep max tracker",
                "Mark targeted areas and equipement",
                "Exercise notes",
              ]}
            />
          </Box>

          <Box mt={10}>
            <Section
              image={
                <img
                  src="workout.png"
                  alt="create workout screenshot"
                  width="50%"
                  style={{ marginTop: 25, minWidth: 280 }}
                />
              }
              title="Take control of your workouts."
              description="Create and play through even the most complex workouts."
              points={["Supersets", "Dropsets", "Repeat"]}
              reverse={!isMobile && isDesktop}
            />
          </Box>

          <Box mt={10}>
            <Section
              image={
                <img
                  src="tools.png"
                  alt="timer, stopwatch, and plate calculator screenshot"
                  width="90%"
                  style={{ marginTop: 25, minWidth: 220, maxWidth: 500 }}
                />
              }
              title="The little things."
              description="Tools and features to make your workouts easier and more efficient."
              points={[
                "Built in plate claculator",
                "Automatic one rep max calculations",
                "On screen timers and stopwatch",
                "Audible notification for halfway and 3-2-1 countdowns",
              ]}
            />
          </Box>

          <Box mt={10}>
            <Section
              image={
                <img
                  src="nutrition_dynamic.png"
                  alt="timer, stopwatch, and plate calculator screenshot"
                  width="60%"
                  style={{ marginTop: 25, minWidth: 250, maxWidth: 400 }}
                />
              }
              title="Achieve your fitness goals."
              description="See progress over time."
              points={[
                "Create and play through custom routines",
                "Dynamic Sets: adjust set based on previous performance",
                "Track daily nutrition alongside workouts",
              ]}
              reverse={!isMobile && isDesktop}
            />
          </Box>

          <Box marginTop={10}>
            <Grid
              container
              direction="row"
              bgcolor="#2a2b2c"
              padding={3}
              justifyContent={{ xs: "center", md: "space-between" }}
              alignItems="center"
              borderRadius={5}
            >
              <Box
                marginBottom={{ xs: 2, md: 0 }}
                textAlign={{ xs: "center", md: "left" }}
              >
                <p style={{ fontSize: 28, fontWeight: 600 }}>
                  Get Talon on the App Store or Play Store today!
                </p>
              </Box>
              <a href="https://apps.apple.com/us/app/talon-custom-workout-tracker/id6452948144">
                <img
                  src="app_store_download.svg"
                  alt="download on app store"
                  height={67}
                  width={200}
                />
              </a>

              <a href="https://play.google.com/store/apps/details?id=com.rz0605.Talon">
                <img
                  src="google-play-badge.png"
                  alt="download on google play"
                  height={64}
                  width={218}
                />
              </a>
            </Grid>
          </Box>

          <Box marginTop={5} marginBottom={5}>
            <Grid
              container
              direction={{ xs: "column", sm: "row" }}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box marginRight={2}>
                <a href=".">
                  <img src="icon.svg" alt="logo" width={64} height={64} />
                </a>
              </Box>

              <p style={{ fontWeight: "bold", fontSize: 20 }}>© Talon 2023</p>

              <Box
                textAlign={{ xs: "center", sm: "left" }}
                marginTop={{ xs: 3, sm: 0 }}
              >
                <a href="/terms">
                  <p style={{ marginBottom: 10 }}>Terms and Conditions</p>
                </a>
                <a href="/privacy">
                  <p style={{ marginBottom: 10 }}>Privacy Policy</p>
                </a>
                <a href="/support">
                  <p>Support</p>
                </a>
              </Box>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
}
